;(function() {
	'use strict';

	var Boxzilla = window.Boxzilla;

	Boxzilla.on('box.show', function( box ) {
		track(box.id, "showed", null);
	});

	Boxzilla.on('box.dismiss', function( box ) {
		track(box.id, 'dismissed' );
	});

	Boxzilla.on('box.interactions.link', function( box, link ) {
		track(box.id, 'interaction', 'link: "' + link.innerText + '"');
	});

	Boxzilla.on('box.interactions.form', function( box, form ) {
		track(box.id, 'interaction', "form" );
	});

	/**
	 * Send an event to Google Analytics
	 *
	 * @param category
	 * @param action
	 * @param label
	 */
	function track( category , action, label ) {

		var analytics = window.ga || window.__gaTracker || false;

		// ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
		if( typeof( analytics ) === "function" ) {

			analytics(function() {
				var trackers = analytics.getAll();

				for(var i=0; i < trackers.length; i++) {
					trackers[i].send('event', "Boxzilla: " + category, action, label, {
						'page': window.location.pathname,
						'nonInteraction': true
					});
				}
			});

			return;
		}

		// old: ga.js
		// _gaq.push(['_trackEvent', eventCategory, eventAction, [eventLabel], [eventValue]])

		var gaTrackers = window._gat || false;
		if( typeof( gaTrackers ) === "object" ) {
			for(var key in gaTrackers.nb) {
				if( ! gaTrackers.nb.hasOwnProperty(key) ) { continue; }
				gaTrackers.nb[key]._trackEvent("Boxzilla: " + category, action, label);
			}

			return;
		}

		//gtag('event', <action>, {'event_category': <category>,'event_label': <label>,'value': <value>});
		var ga4 = window.gtag || false;
		if( typeof( ga4 ) === "function" ) {
			ga4('event', action, {
				'event_category': 'Boxzilla: ' + category,
				'event_label': label
			});
			return;
		}

		// If code got to this point: Uh oh. No Google Analytics object found.
		console.log( "Boxzilla: Unable to find Google Analytics tracking object.");
	}
})();
